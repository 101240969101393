import { Link } from 'gatsby';
import React, { FC, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Container, Menu } from 'semantic-ui-react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import UserContext from './UserContext';

const VFA_BLUE = '#172559';

interface MainContainerProps {
  hidePadding: boolean;
}
const MainContainer = styled(Container, { shouldForwardProp: isPropValid })`
  padding: ${({ hidePadding }: MainContainerProps) =>
    hidePadding ? '0' : '60px 0 40px'};
`;

const Logo = styled.img`
  min-width: 150px;
`;

interface LayoutProps {
  hidePadding?: boolean;
}

const Layout: FC<LayoutProps> = ({ children, hidePadding }) => {
  const userContext = useContext(UserContext);
  return (
    <div>
      <Menu
        fixed="top"
        color="purple"
        inverted
        style={{ background: VFA_BLUE }}
      >
        <Container>
          <Menu.Item header as={Link} to="/">
            <Logo src="/logo_white.svg" alt="VFA Dashboard" />
          </Menu.Item>
          <Menu floated="right" style={{ background: VFA_BLUE }} inverted>
            {!userContext && (
              <Menu.Item as={Link} to="/login">
                Log in
              </Menu.Item>
            )}
            {userContext && (
              <>
                <Menu.Item>{userContext.attributes.email}</Menu.Item>
                <Menu.Item
                  onClick={() =>
                    // hacky fix to https://github.com/aws-amplify/amplify-js/issues/6842
                    Auth.signOut().then(() => window.location.reload())
                  }
                >
                  Log out
                </Menu.Item>
              </>
            )}
          </Menu>
        </Container>
      </Menu>
      <MainContainer hidePadding={hidePadding}>{children}</MainContainer>
    </div>
  );
};

export default Layout;
