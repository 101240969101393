import React, { useContext } from 'react';
import { Link, PageProps } from 'gatsby';
import { Tab } from 'semantic-ui-react';

import Layout from '@/components/Layout';
import UserContext from '@/components/UserContext';
import { DbSwitcher } from '@/components/DbContext';
import ExportTab from './index/ExportTab';
import TimeseriesTab from './index/TimeseriesTab';
import DebugTab from './index/DebugTab';

const Home: React.FC<PageProps> = () => {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = 'VFA Dashboard';
    }
  }, []);

  const userContext = useContext(UserContext);

  const groups: string[] =
    userContext?.signInUserSession?.accessToken?.payload?.['cognito:groups'] ??
    [];

  const panes = [
    {
      menuItem: 'Export',
      render: () => (
        <Tab.Pane style={{ border: 'none' }}>
          <ExportTab />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Timeseries',
      render: () => (
        <Tab.Pane style={{ border: 'none' }}>
          <TimeseriesTab />
        </Tab.Pane>
      ),
    },
  ];
  if (groups.includes('AwsDebug')) {
    panes.push({
      menuItem: 'AWS Debug Info',
      render: () => (
        <Tab.Pane style={{ border: 'none' }}>
          <DebugTab />
        </Tab.Pane>
      ),
    });
  }

  return (
    <Layout>
      {!userContext && (
        <>
          <p>Welcome to the VFA Dashboard!</p>
          <p>
            Please <Link to="/login">Log in</Link> to get started
          </p>
        </>
      )}
      {userContext && groups.includes('AwsDebug') && <DbSwitcher />}
      {userContext && <Tab panes={panes} />}
    </Layout>
  );
};

export default Home;
